<template lang="html">
	<div class="container-fluid p-5">
		<loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
		<div class="row">
			<div class="col-md-12">
				<div class="card">
					<div class="card-header">
						<h6>အပေါင် လက်ခံ <small>(Fields in red are required)</small></h6>
					</div>
					<div class="card-body">
						<form v-on:submit.prevent="onSubmit">

							<div v-if="this.$role == 'admin'" class="form-group row">
								<label for="inputShopId" class="col-sm-2 col-form-label">ဆိုင်ခွဲ အမည်</label>
								<div class="col-sm-10">
									<select id="inputShopId" class="form-control" v-model="mortgageRequest.shopId"
										@change="validationRule()">
										<option v-for="shop in shopList" :value="shop.id">{{shop.name}}</option>
									</select>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputItemId" class="col-sm-2 col-form-label text-danger">ပစ္စည်း
									အမျိုးအစား</label>
								<div class="col-sm-10">
									<select id="inputItemId" class="form-control" v-model="mortgageRequest.itemId"
										@change="validationRule()">
										<option value="">ရွေးချယ်မည်</option>
										<option v-for="item in itemList" :value="item.id">{{item.name}}</option>
									</select>
									<small class="text-danger">{{validationData.itemId}}</small>
								</div>
							</div>
							<!--  -->
							<div class="form-group row">
								<label for="inputMortgageNo" class="col-sm-2 col-form-label">ဘောင်ချာ
									အမှတ်</label>
								<div class="col-sm-10">
									<select class="form-control" v-model="mortgageRequest.noType"
										@change="validationRule()">
										<option value="No">ဆိုင်ခွဲမရှိ</option>
										<option value="A">A</option>
										<option value="B">B</option>
									</select>
								</div>
								<!-- <div class="col-sm-7">
									<input type="text" v-model="mortgageRequest.no" @blur="validationRule()"
										class="form-control" id="inputMortgageNo">
									<small class="text-danger">{{validationData.no}}</small>
								</div> -->
							</div>
							<!--  -->
							<div class="form-group row">
								<label for="inputMortgageType" class="col-sm-2 col-form-label text-danger">ပစ္စည်း
									နာမည်</label>
								<div class="col-sm-10">
									<input type="text" v-model="mortgageRequest.type" @blur="validationRule()"
										class="form-control" id="inputMortgageType">
									<small class="text-danger">{{validationData.type}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputLoan" class="col-sm-2 col-form-label text-danger">ချေးငွေ (ks) </label>
								<div class="col-sm-10">
									<input type="number" v-model="mortgageRequest.loan" @keyup="changeFont()"
										@blur="validationRule()" class="form-control" id="inputLoan">
									<small class="text-danger">{{validationData.loan}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputLoanMMK" class="col-sm-2 col-form-label text-danger">ချေးငွေ (ကျပ်)
								</label>
								<div class="col-sm-10">
									<input type="text" v-model="mortgageRequest.loanMmk" @blur="validationRule()"
										class="form-control" id="inputLoanMMK">
									<small class="text-danger">{{validationData.loanMmk}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputLoanText" class="col-sm-2 col-form-label text-danger">ချေးငွေ (စာဖြင့်)
								</label>
								<div class="col-sm-10">
									<input type="text" v-model="mortgageRequest.loanText" @blur="validationRule()"
										class="form-control" id="inputLoanText">
									<small class="text-danger">{{validationData.loanText}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputName" class="col-sm-2 col-form-label text-danger">ပေါင်နှံသူ အမည်
								</label>
								<div class="col-sm-10">
									<input type="text" v-model="mortgageRequest.name" @blur="validationRule()"
										class="form-control" id="inputName">
									<small class="text-danger">{{validationData.name}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputNrc" class="col-sm-2 col-form-label">မှတ်ပုံတင် အမှတ် </label>
								<div class="col-sm-10">
									<input type="text" v-model="mortgageRequest.nrc" @blur="validationRule()"
										class="form-control" id="inputNrc">
								</div>
							</div>

							<div class="form-group row">
								<label for="inputAddress" class="col-sm-2 col-form-label text-danger">နေရပ်လိပ်စာ
								</label>
								<div class="col-sm-10">
									<textarea v-model="mortgageRequest.address" @blur="validationRule()"
										class="form-control" id="inputAddress"></textarea>
									<small class="text-danger">{{validationData.address}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputInterest" class="col-sm-2 col-form-label text-danger">အပေါင်သက်တမ်း</label>
								<div class="col-sm-10">
									<select id="inputInterest" class="form-control" v-model="mortgageRequest.interestId"
										@change="calcInterest()">
										<option value="">ရွေးချယ်မည်</option>
										<option v-for="interest in interestList" :value="{ id: interest.id, month: interest.period }">{{interest.name}}</option>
									</select>
									<small class="text-danger">{{validationData.interestId}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputStartDate"
									class="col-sm-2 col-form-label text-danger">ပေါင်နှံသည့်နေ့စွဲ </label>
								<div class="col-sm-10">
									<input type="date" v-model="mortgageRequest.startDate" @change="changeDate()"
										@blur="validationRule()" class="form-control" id="inputStartDate">
									<small class="text-danger">{{validationData.startDate}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputEndDate"
									class="col-sm-2 col-form-label text-danger">အပေါင်သက်တမ်းကုန်ဆုံးမည့်နေ့ </label>
								<div class="col-sm-10">
									<input type="date" v-model="mortgageRequest.endDate" @blur="validationRule()"
										class="form-control" id="inputEndDate" disabled>
									<small class="text-danger">{{validationData.endDate}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputInterestType"
									class="col-sm-2 col-form-label text-danger">အတိုးနှုန်း</label>
								<div class="col-sm-5">
									<select class="form-control" v-model="mortgageRequest.interestType" disabled>
										<option value="percent">ရာခိုင်နှုန်း</option>
										<option value="fix">ပုံသေ</option>
									</select>
								</div>
								<div class="col-sm-5">
									<input type="number" v-model="mortgageRequest.interestRate" @blur="validationRule()"
										class="form-control" step="0.1">
									<small class="text-danger">{{validationData.interestRate}}</small>
								</div>
							</div>


							<button type="submit" class="btn btn-primary float-save">လုပ်ဆောင်မည်</button>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="js">
	import {
		mapState,
		mapActions
	} from 'vuex'
	import env from '../../enviorments'
	import Loading from 'vue-loading-overlay'
	import 'vue-loading-overlay/dist/vue-loading.css';
	import moment from 'moment';
	export default {
		name: 'mortgageCreate',
		components: {
			Loading
		},
		data() {
			return {
				env,
				isLoading: false,
				fullPage: true,
				referenceShow: false,
				shopList: [],
				itemList: [],
				interestList: [],
				mortgageRequest: {
					id: "",
					shopId: "",
					itemId: "",
					noType: "No",
					no: "",
					type: "",
					loan: "",
					loanMmk: "",
					loanText: "",
					name: "",
					nrc: "",
					address: "",
					interestId: "",
					startDate: "",
					endDate: "",
					interestType: "percent",
					interestRate: 10
				},
				validationData: {
					shopId: "",
					itemId: "",
					no: "",
					type: "",
					loan: "",
					loanMmk: "",
					loanText: "",
					name: "",
					nrc: "",
					address: "",
					interestId: "",
					startDate: "",
					endDate: "",
					interestRate: "",
				}
			}
		},
		watch: {
			async $route(to, from) {
				if (to.params.id) {
					this.mortgageRequest.id = to.params.id
				}
			},
		},
		computed: {},
		methods: {
			...mapActions({
				mortgageClothFieldsAction: 'mortgageClothFieldsAction',
				mortgageClothStoreAction: 'mortgageClothStoreAction',
			}),
			changeFont() {
				let myNumber = this.toMyanmar(this.mortgageRequest.loan, "my")
				this.mortgageRequest.loanMmk = myNumber
				let myWord = this.myanmarNumToWord(this.mortgageRequest.loan)
				this.mortgageRequest.loanText = myWord
			},
			changeDate() {
				let month = this.mortgageRequest.interestId.month
				this.mortgageRequest.endDate = moment(this.mortgageRequest.startDate).add(month, 'M').format('YYYY-MM-DD')
			},
			calcInterest() {
				let month = this.mortgageRequest.interestId.month
				this.mortgageRequest.endDate = moment(this.mortgageRequest.startDate).add(month, 'M').format('YYYY-MM-DD')
			},
			async getFields() {
				let option = {
					fields: {
						type: "field"
					}
				}
				await this.mortgageClothFieldsAction({
					...option
				}).then(res => {
					this.mortgageRequest.interestId = {
						"id" : res.data.data.interestId.id,
						"month": res.data.data.interestId.period
					}
					this.mortgageRequest.shopId = res.data.data.shop[0].id
					this.shopList = res.data.data.shop
					this.itemList = res.data.data.item
					this.interestList = res.data.data.interest
					this.calcInterest()
				}).catch(err => this.isLoading = true)
			},
			async onSubmit() {
				this.isLoading = true
				let validation = this.validationRule()
				if (validation == true) {
					this.isLoading = false
					return
				}
				let option = this.mortgageRequest
				await this.mortgageClothStoreAction({
					...option
				}).then(res => {
					this.$router.replace({
						path: 'cloth-print',
						query: {
							page: 1,
							reverse: 'desc',
							sort: 'created_at',
							notype: '',
							nofrom: '',
							noto: '',
							no: '',
							name: '',
							address: '',
							loan: '',
							shop: '',
							item: '',
							startdate: '',
							enddate: '',
							status: '',
							referencestatus: '',
							nodate: '',
							paydate: '',
							paydate_to: '',
							expdate_from: '',
							expdate_to: '',
							otherstatus: '',
							inventoryMode: '',
							id: res.data.id
						}
					}).catch(() => {})
					this.isLoading = false;
				}).catch(err => this.isLoading = true)
			},
			validationRule() {
				let isValidation = []
				if (this.mortgageRequest.itemId == "") {
					this.validationData.itemId = "ပစ္စည်းအမျိုးအစား ဖြည့်ပါ။"
					isValidation.push(true)
				} else {
					this.validationData.itemId = ""
					isValidation.push(false)
				}
				// if (this.mortgageRequest.no == "") {
				// 	this.validationData.no = "ဘောင်ချာ အမှတ် ဖြည့်ပါ။"
				// 	isValidation.push(true)
				// } else {
				// 	this.validationData.no = ""
				// 	isValidation.push(false)
				// }
				if (this.mortgageRequest.type == "") {
					this.validationData.type = "ပစ္စည်းအမည် ဖြည့်ပါ။"
					isValidation.push(true)
				} else {
					this.validationData.type = ""
					isValidation.push(false)
				}
				if (this.mortgageRequest.loan == "") {
					this.validationData.loan = "ချေးငွေ (ks) ဖြည့်ပါ။"
					isValidation.push(true)
				} else {
					this.validationData.loan = ""
					isValidation.push(false)
				}
				if (this.mortgageRequest.loanMmk == "") {
					this.validationData.loanMmk = "ချေးငွေ (ကျပ်) ဖြည့်ပါ။"
					isValidation.push(true)
				} else {
					this.validationData.loanMmk = ""
					isValidation.push(false)
				}
				if (this.mortgageRequest.loanText == "") {
					this.validationData.loanText = "ချေးငွေ (စာဖြင့်) ဖြည့်ပါ။"
					isValidation.push(true)
				} else {
					this.validationData.loanText = ""
					isValidation.push(false)
				}
				if (this.mortgageRequest.name == "") {
					this.validationData.name = "ပေါင်နှံသူ အမည် ဖြည့်ပါ။"
					isValidation.push(true)
				} else {
					this.validationData.name = ""
					isValidation.push(false)
				}
				if (this.mortgageRequest.address == "") {
					this.validationData.address = "နေရပ်လိပ်စာ ဖြည့်ပါ။"
					isValidation.push(true)
				} else {
					this.validationData.address = ""
					isValidation.push(false)
				}
				if (this.mortgageRequest.interestId == "") {
					this.validationData.interestId = "အပေါင်သက်တမ်း ဖြည့်ပါ။"
					isValidation.push(true)
				} else {
					this.validationData.interestId = ""
					isValidation.push(false)
				}
				if (this.mortgageRequest.startDate == "") {
					this.validationData.startDate = "ပေါင်နှံသည့်နေ့စွဲ ဖြည့်ပါ။"
					isValidation.push(true)
				} else {
					this.validationData.startDate = ""
					isValidation.push(false)
				}
				if (this.mortgageRequest.endDate == "") {
					this.validationData.endDate = "အပေါင်သက်တမ်းကုန်ဆုံးမည့်နေ့ ဖြည့်ပါ။"
					isValidation.push(true)
				} else {
					this.validationData.endDate = ""
					isValidation.push(false)
				}
				if (this.mortgageRequest.interestRate == "") {
					this.validationData.interestRate = "အတိုးနှုန်း ဖြည့်ပါ။"
					isValidation.push(true)
				} else {
					this.validationData.interestRate = ""
					isValidation.push(false)
				}
				if (isValidation.includes(true)) {
					return true
				} else {
					return false
				}
			}
		},
		async mounted() {
			this.mortgageRequest.startDate = moment(new Date()).format('YYYY-MM-DD')
			// this.mortgageRequest.endDate = moment().add(4, 'M').format('YYYY-MM-DD')
			this.getFields()
		}
	}
</script>

<style scoped lang="scss">
	.v-text-field--box .v-input__slot,
	.v-text-field--outline .v-input__slot {
		min-height: 56px;
	}

	.banner-image {
		width: 100px;
		height: 100px;
		object-fit: contain;
	}
</style>